body {
  background-color:#EFEFEF;
}
.main {
  background-color:white;
}
.logo {
  padding-top:150px;
  margin-bottom:50px;
  display:block;
}
.spacer {
  padding-top:70px;
}
.item {
  margin:30px 0px;
}
.barTitle {
  flex-grow: 1;
}
.warning {
  padding:10px;
  margin-bottom:20px;
  margin-top:10px;
}
.textfield {
  margin-right:15px !important;
  margin-bottom:10px !important;
}

#affiliations {
  -webkit-transform: scale(0.7); /* Saf3.1+, Chrome */
     -moz-transform: scale(0.7); /* FF3.5+ */
      -ms-transform: scale(0.7); /* IE9 */
       -o-transform: scale(0.7); /* Opera 10.5+ */
          transform: scale(0.7);
             /* IE6–IE9 */
             

}